import React from 'react';
import './customBox.css';

const Square = ({ text }) => {
  return (
    <div className="container">
      <div className="square">
        <span className="font-first-custom px-8">{text}</span>
      </div>
    </div>
  );
}

export default Square;
