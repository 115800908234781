// src/VideoPlayer.js
import React from 'react';
import promoVideo from './promo.mov';
import './videoPlayer.css';

const VideoPlayer = () => {
  return (
    <div className='video-container'>
      <video width="100%" controls>
        <source src={promoVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoPlayer;
