import React from 'react';
import './HowToBuy.css';

function HowToBuy() {
  return (
    <div className="how-to-buy-container font-first bg-darkerGrey">
      <div className="how-to-buy-content">
        <div className="image-container">
          <img src="/poker.jpg" alt="Dogs Playing Poker" className="imageion" />
        </div>
      </div>
    </div>
  );
}

export default HowToBuy;
