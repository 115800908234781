import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import './navbar.css'; // Ensure this imports your global styles
import Marquee from "react-fast-marquee";

function NavBar() {
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    const navbarHeight = document.querySelector('.navbar-wrapper').offsetHeight;
    const offsetPosition = section.offsetTop - navbarHeight;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  };

  const [navOpen, setNavOpen] = useState(false);
  const [socialOpen, setSocialOpen] = useState(false);


  const toggleSocial = () => {
    setSocialOpen(!socialOpen);
  };

  const closeSocial = () => {
    setSocialOpen(false);
  };



  const toggleNav = () => {
    setNavOpen(!navOpen);
  };


  return (
    <div className="navbar-wrapper">
      <Marquee speed={100} loop={0} autoFill="true">
        <span className="scrolling-text font-first">
          I MEAN BRO, IT'S LITERALLY THE DOGS IN THEIR HATS
        </span>
      </Marquee>
      <nav className="navbar">
        <div className="navbar-container">
          <div className={`nav-buttons ${navOpen ? 'open' : ''}`}>
            <button onClick={() => scrollToSection('section1')} className="font-smaller hops">INTRODUCTION</button>
            <button onClick={() => scrollToSection('section2')} className="font-smaller hops">API TOOL</button>
            <button onClick={() => scrollToSection('section3')} className="font-smaller hops">CHART WIF $DOGS</button>
            <button onClick={() => scrollToSection('section5')} className="font-smaller hops">COMMUNITY</button>
            <div
              className="socials-dropdown"
              onMouseLeave={closeSocial}  // This will close the dropdown when the mouse leaves the area
            >
              <button onClick={toggleSocial} className="font-smaller hops">SOCIALS</button>
              <div className={`dropdown-content ${socialOpen ? 'open' : ''}`}>
                <a href="https://telegram.org" target="_blank" rel="noopener noreferrer" className='font-smaller'>Telegram</a>
                <a href="https://x.com/MeWifTheDogs" target="_blank" rel="noopener noreferrer" className='font-smaller'>Twitter</a>
              </div>
            </div>
          </div>
          <div className="hamburger" onClick={toggleNav}>
            <FontAwesomeIcon icon={faBars} size="2x" color="white" />
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
