// src/components/Introduction.js
import React from 'react';
import './Introduction.css';

function Introduction() {
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    const navbarHeight = document.querySelector('.navbar-wrapper').offsetHeight;
    const offsetPosition = section.offsetTop - navbarHeight;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  };


  return (
    <div className="introduction-container">
      <h1 className="heading font-first text-white">
        INTRODUCTION
      </h1>
      <p className="content font-smaller">
        <span className="tool">WIF</span> HAS BECOME A SUPERSTAR ACROSS THE CRYPTO WORLD.<br /><br />

        BUT HE WOULDN'T OF BEEN ABLE TO DO IT WITHOUT HIS <span className="tool">$DOGS</span>.<br /><br />

        INSPIRED BY THE VIRAL <span className="quote">"ME AND THE BOYS"</span> MEME, WE BELIEVE THERE'S NO BETTER MEME THAT SHOWCASES HOW A COMMUNITY OF <span className="tool">$DOGS</span> CAN ALL COME TOGETHER AND WIN AS ONE.<br /><br />

        WE WANT TO USE THIS PROJECT TO RECOGNISE THE COMMUNITY THAT FULLY GOT BEHIND <span className="tool">WIF</span>.<br /><br />

        GIVE HIS FOLLOWERS THE CHANCE TO PROSPER ONCE AGAIN.<br /><br />

        THE GOAL IS TO BUILD THE COMMUNITY AS BIG AS POSSIBLE. FULL OF <span className="tool">$DOGS</span>, WEARING HATS.<br /><br />

        <span className="tool">WIF</span> HAS GOT HIS ICONIC IMAGE. NOW IT'S TIME FOR YOU <span className="tool">$DOGS</span> TO GET YOURS.<br /><br />

        USE OUR DESIGNATED <span className="highlighter" onClick={() => scrollToSection('section2')}>API TOOL</span> TO CUSTOMISE YOUR DOG.<br /><br />

        ONCE YOU HAVE CUSTOMISED, UPLOAD YOUR IMAGE TO THE <span className="tool">$DOGS</span> TWITTER/TELEGRAM.<br /><br />

        LOOK OUT FOR COMPETITIONS AND PRIZES FOR THE COOLEST DOGS.<br /><br />

        LOVE TO ALL OUR <span className="tool">$DOGS</span>!<br /><br />
        X
      </p>
    </div>
  );
}

export default Introduction;
