// src/components/Footer.js
import React from 'react';
import './footer.css';

function Footer() {
  return (
    <div className="footer-container font-first bg-mainGray">
      <div className="footer-links">
        <a href="#chart" className="footer-link">CHART</a>
        <a href="#contract" className="footer-link">CONTRACT</a>
        <a href="#telegram" className="footer-link">TELEGRAM</a>
        <a href="https://x.com/MeWifTheDogs" className="footer-link">TWITTER</a>
      </div>
    </div>
  );
}

export default Footer;
