import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const DashedDivider = () => {
  const dividerStyle = {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    margin: '30px 0 0 0',
  };

  const lineStyle = {
    flex: 1,
    borderBottom: '4px dashed #fff',
  };

  const iconWrapperStyle = {
    backgroundColor: '#fff',
    color: '#333',
    borderRadius: '50%',
    padding: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)',
    margin: '0 10px', // Adjust margin for responsiveness
  };

  return (
    <div style={dividerStyle}>
      <div style={{ ...lineStyle, marginRight: '10px' }} />
      <div style={iconWrapperStyle}>
        <FontAwesomeIcon icon={faChevronDown} />
      </div>
      <div style={{ ...lineStyle, marginLeft: '10px' }} />
    </div>
  );
};

export default DashedDivider;
