import React from 'react';
import './title.css';

function MainTitle() {
  return (
    <div className="flex items-center justify-center py-8 mt-2">
       <span className='titeling font-first text-white mr-4'>ME WIF THE</span>
       <div className='squareTitle'>
            <span className='font-first'>$DOGS</span>
        </div>
    </div>
  );
}

export default MainTitle;
