import React from 'react';

function Jupiter() {
    return (
        <div>
            <script src='https://terminal.jup.ag/main-v3.js' />
            <div id="integrated-terminal"></div>
        </div>
    );
}

export default Jupiter;