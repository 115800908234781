// src/components/ImageStyleSelection.js
import React from 'react';
import './ImageStyleSelection.css';
import Square from '../customBox/customBox';

function ImageStyleSelection() {
  return (
    <div className="image-style-container font-first">
        <Square text="SIMPLY SELECT YOUR IMAGE STYLE" />
        <p className="text mb-10">
          SELECT FROM EITHER THE ORIGINAL <span className="highlighting">WIF</span> STYLE IMAGE OR OUR CUSTOM <span className="highlighting">WIF</span> ART STYLE. WE HAVE INCLUDED DIFFERENT VARIATIONS OF THESE MAKING IT EASIER FOR YOU TO DESIGN YOUR DOG.
        </p>
        <Square text="SELECT FROM VARIOUS HAT STYLES" />
        <p className="text mb-10">
          WE HAVE INCLUDED VARIOUS HAT STYLES FOR YOU TO CHOOSE FROM WITH MANY MORE TO BE ADDED FURTHER DOWN THE LINE.
        </p>
        <Square text="CHOOSE FROM EXTRA ACCESSORIES IF YOU WISH" />
        <p className="text mb-10">
          WE WANT EVERY DOG TO HAVE THEIR OWN UNIQUE LOOK, JUST LIKE <span className="highlighting">WIF</span>. NO ONE WANTS TO BE THE SAME. THESE EXTRAS CAN MAKE YOU UNIQUE.
        </p>
        <Square text="DOWNLOAD YOUR DOG" />
        <p className="text">
          DOWNLOAD YOUR DOG AND SET AS YOUR PROFILE PICTURE ON TWITTER/TG TO AUTOMATICALLY ENTER ALL COMPETITIONS.
        </p>

        <p className="mt-12 yellow-text">
        LOOK OUT FOR FUTURE UPDATES/RELEASES FOR YOU TO ADD TO AND CHANGE YOUR DOG. MANY COMPETITIONS ARE PLANNED IN THE COMMUNITY OPEN FOR ALL <span className="highlighting">$DOGS</span>. MAY THE BEST DOG WIN!
        </p>
    </div>
  );
}

export default ImageStyleSelection;
