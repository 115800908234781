import React from 'react';
import './DexTools.css'; // Ensure this imports your global styles

const DEXToolsChartWidget = () => {
  return (
    <div id="section3Inside">
        <h2 className="heading font-first text-white">CHART WIF <a className='text-mint'>$DOGS</a></h2>
        <iframe
            id="dextools-widget"
            title="DEXTools Trading Chart"
            width="100%"
            height="600px"
            src="https://dexscreener.com/solana/EP2ib6dYdEeqD8MfE2ezHCxX3kP3K2eLKkirfPm5eyMx?embed=1&theme=dark"
            allowFullScreen
        ></iframe>
    </div>
    );
};

export default DEXToolsChartWidget;
