// src/components/StyledTextWithEffects.js
import React from 'react';
import './StyledTextWithEffects.css';

function StyledTextWithEffects() {
  return (
    <div className="text-effects-container font-first">
    <img
      src='/image_o.gif'
      alt="Example GIF"
      className="my-gif"
      style={{ width: '1200px', height: 'auto' }}
    />
  </div>
  );
}

export default StyledTextWithEffects;
