// src/components/Community.js
import React from 'react';
import './Community.css';

function Community() {
  return (
    <div className="community-container font-first">
      <h2 className="heading">COMMUNITY</h2>
      <div className="image-container">
        <img src="/Image2.jpg" alt="Community" className="community-image" />
      </div>
      <p className="community-text">
        A COMMUNITY. A BROTHERHOOD. A PACK OF <span className="highlighting">$DOGS</span>. TOGETHER FOREVER. UNITED AS ONE. WITH MAN BEING A <span className="highlighting">$DOGS</span> BEST FRIEND, A HAT IS A <span className="highlighting">$DOGS</span> BEST FRIEND. ALL YOU NEED IS YOUR <span className="highlighting">$DOGS</span> AND A HAT. CHOOSE YOUR HAT AND JOIN THE <span className="highlighting">$DOGS</span> ALL THE WAY TO THE TOP.
      </p>
    </div>
  );
}

export default Community;
